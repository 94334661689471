<script setup lang="ts">
import {
  DocumentStatus,
  type UnlinkDocumentVariables,
  type IDocument,
} from '@register'
import AmendmentDocumentTitle from '@register/components/Amendment/Document/AmendmentDocumentTitle.vue'

const props = defineProps<{ document: IDocument | undefined }>()

defineEmits<{
  publish: []
  add: []
  unlink: [variables: UnlinkDocumentVariables]
}>()

const isNotPublished = computed(
  () => props.document?.status === DocumentStatus.NOT_PUBLISHED,
)
</script>

<template>
  <div
    class="sticky top-0 z-20 grid grid-cols-1 justify-between rounded-t-lg bg-[#44444490] px-4 py-2 backdrop-blur-2xl backdrop-saturate-[180%] group-hover:bg-[#555]"
  >
    <div class="flex w-full flex-col justify-between md:flex-row">
      <div class="flex items-center gap-2">
        <AmendmentDocumentTitle :document="document" />
        <!-- Not published status  -->

        <VTooltip
          v-if="document && isNotPublished"
          class="bg-error text-xs text-white"
          :delay="10"
          placement="top"
          :distance="12"
          :auto-hide="true"
        >
          <span
            class="bg-error flex rounded-lg px-2 py-0.5 text-xs font-normal text-red-50"
          >
            Not published
          </span>

          <template #popper>
            <div class="text-xs">
              Unpublished amendment won't change the original lease
            </div>
          </template>
        </VTooltip>

        <span
          v-else-if="!document?.filename"
          class="flex text-xs font-normal text-gray-400"
        >
          * Amendments without document will be automatically published after
          activeted
        </span>
      </div>

      <div class="flex justify-between gap-x-4">
        <Button color="transparentPrimary" size="sm" @click="$emit('add')">
          Add, change or remove a term
        </Button>

        <div v-if="document" class="flex items-center gap-x-4">
          <Button v-if="isNotPublished" size="sm" @click="$emit('publish')">
            Publish
          </Button>

          <ActionMenu>
            <ActionMenuItem
              color="transparent-danger"
              @click="$emit('unlink', { documentId: document.id })"
            >
              Unlink document
            </ActionMenuItem>
          </ActionMenu>
        </div>
      </div>
    </div>

    <!-- Table header (for the file AmendmentTable.vue ) -->
    <div
      class="hidden grid-cols-12 gap-2 py-2 text-sm font-bold text-gray-500 group-hover:text-gray-300 md:grid"
    >
      <!-- Effective date -->
      <div class="col-span-7 md:col-span-1">Executed at</div>
      <div class="col-span-7 md:col-span-1">Effective at</div>

      <!-- Type -->
      <div class="col-span-4 text-right md:col-span-2 md:text-left">Type</div>

      <div class="col-span-full md:col-span-2">Varied Item</div>

      <!-- Value -->
      <div class="col-span-full whitespace-pre md:col-span-4">New value</div>

      <!-- Active -->
      <div class="col-span-8 md:col-span-1">Status</div>

      <!-- Actions -->
      <div
        class="col-span-3 flex items-end space-x-2 md:col-span-1 md:items-center"
      ></div>
    </div>
  </div>
</template>
